import './App.css';
import SupersetDashboard from "./components/supersetDashboard";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {
  return (
    <div className="MainApp">
        <Router>
            <Routes>
                <Route path="/supersetDashboard/:dashboardID" element={<SupersetDashboard />} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;
